$(document).ready ->
  $('.btn-mob').click (e)->
    e.preventDefault();
    $(@).find('i').toggleClass 'active'
    $('.header .menu').toggleClass 'open'
  $('ul.default-list ul.active').show()
  $('ul.default-list').find('> li > a + ul').prev().click (e)->
    e.preventDefault()
    $(@).toggleClass("active").next().stop().slideToggle().toggleClass("active")

  $("[data-mask]").mask($(@).data("mask"))
  $('.slick-work').slick({
    slidesToShow: 2
    slidesToScroll: 1
    dots: true
    responsive: [
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    ]
  })
